import React from "react"
import Eventcard from "../../../components/eventscard"
import "../../../assets/styles/page/docevent.scss"
import Layout from "../../../components/layout"

const events = () => {
  const eventsArray = [
    {
      id: "1",
      eventsImage: "https://source.unsplash.com/600x600/?sig=59",
      season: "Season one",
      title: "Launching Brooi",
      description:
        "	The first season of Brooi Design—Talks was all about boundaries. What does it mean to be inside or outside, or to belong safely in a community?"
    },
    {
      id: "1",
      eventsImage: "https://source.unsplash.com/600x600/?sig=1",
      season: "Season two",
      title: "Launching Brooi",
      description:
        "	The first season of Brooi Design—Talks was all about boundaries. What does it mean to be inside or outside, or to belong safely in a community?"
    }
  ]
  return (
    <>
      <Layout>
        <div className="event--page--cointainer">
          <div className="event--header">
            <p className="event--title--subtitles">Brooi Design—Talks</p>
          </div>
          <div className="event--list">
            {eventsArray.map(event => {
              const { eventsImage, season, description, title, id } = event
              return (
                <div className="event--card--wrapper--container" key={id}>
                  <Eventcard
                    key={id}
                    eventsImage={eventsImage}
                    season={season}
                    title={title}
                    description={description}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default events
